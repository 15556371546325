<template>
	<div id="responsive-map">
		<alarms-list
			:idMaquina="selectedMarker && selectedMarker.maquina.id"
			:style="`width: ${alarmsPanelWidth}px;`"
		/>
		<gmap-map
			class="map"
			ref="mapRef"
			:center="center"
			:zoom="zoom"
			map-type-id="hybrid"
			:style="`width: calc(100% - ${sideBarWidth}px - ${alarmsPanelWidth}px); margin-left: ${sideBarWidth}px;`"
		>
			<gmap-marker
				:key="index"
				v-for="(m, index) in markers"
				:position="m.position"
				:clickable="true"
				:icon="{
					url: getIcon(m.maquina.id),
					scaledSize: {
						width: Number((m.maquina.summary && m.maquina.summary.width) || 27),
						height: Number((m.maquina.summary && m.maquina.summary.height) || 43)
					},
					labelOrigin: {
						x: Number((m.maquina.summary && m.maquina.summary.labelX) || 15),
						y: -10
					},
				}"
				:label="{ text: exibirNome ? m.maquina.nome : ' ', color: m.maquina.cor, fontWeight: 'bold', className: m.maquina.bgClass }"
				@dblclick="zoomIn(m)"
				@click="selectedMarker = m"
			/>
		</gmap-map>

		<machine-summary
			v-if="selectedMarker"
			:maquina="maquinas.find(m => m.id == selectedMarker.maquina.id)"
			@close="selectedMarker = null"
		/>
	</div>
</template>

<script>
	import { mapState } from "vuex";

	import AlarmsList from "@/components/Dashboard/AlarmsList.vue";
	import MachineSummary from "@/components/Dashboard/MachineSummary.vue";

	import { LocalizationService } from "@/services/localization";
	import { MaquinasService } from "@/services/maquinas";
	import { PreferencesService } from "@/services/preferencias";
	import { AuthService } from "@/services/auth";

	const UPDATE_TIMEOUT = 5 * 1000;

	export default {
		components: { AlarmsList, MachineSummary },

		data () {
			return {
				maquinas: [],
				selectedMarker: null,
				alarmsPanelWidth: 350,
				preferences: [],
				exibirNome: false,

				localizationService: new LocalizationService(),
				maquinasService: new MaquinasService(),
				preferenceService: new PreferencesService(),
				authService: new AuthService(),

				zoom: 7,
				markers: [],
				center: { lat: -20, lng: -44 },
				updateInterval: null,

				/**
				 * Associa a id da máquina a flag de requisição de localização pendente
				 * @type {Record<number, boolean>}
				 */
				temRequisicaoPendente: {}
			};
		},

		computed: mapState(["sideBarWidth"]),

		async mounted () {
			try {
				this.getSettings();
				this.maquinas = (await this.maquinasService.listMachines())
					.filter(m => m.identificador_localizacao);

				// Monta array para verificação de requisições
				for (const m of this.maquinas)
					this.temRequisicaoPendente[m.id] = false;

				this.updateMachinesSummary();
				await this.updatePositions();

				this.updateInterval = setInterval(() => {
					this.updatePositions();
					this.updateMachinesSummary();
				}, UPDATE_TIMEOUT);
			} catch (error) {
				console.error(error);
				if (error.response?.status === 403)
					await this.authService.requireLogin();
			}
		},

		beforeDestroy () {
			clearInterval(this.updateInterval);
		},

		methods: {
			zoomIn (m) {
				this.center.lat = m.position.lat + 0.01;
				this.center.lng = m.position.lng + 0.01;
				this.zoom = 17;

				// Altera os valores duas vezes para garantir que o Vue vai
				// repassa-los para o mapa, mesmo que já fossem iguais,
				// pois o usuário pode ter movido.
				setImmediate(() => {
					this.center.lat = m.position.lat;
					this.center.lng = m.position.lng;
					this.zoom = 19;
				});
			},

			async getSettings () {
				this.preferences = await this.preferenceService.getPreferences(["EXIBIR_NOME_MARCADOR_MAPAS", "ICONE_MARCADOR"]);
				this.exibirNome = (this.preferences.find(p => p.nome === "EXIBIR_NOME_MARCADOR_MAPAS") || {}).valor === "true";
			},

			async updatePositions () {
				try {
					const promises = [];
					for (const m of this.maquinas) {
						if (!this.temRequisicaoPendente[m.id])
							promises.push(this.updateMarker(m.identificador_localizacao, m));
					}
					await Promise.all(promises);
					this.$forceUpdate();
				} catch (error) {
					console.error(error);
					if (error.response?.status === 403)
						await this.authService.requireLogin();
				}
			},

			async updateMarker (id, maquina) {
				try {
					this.temRequisicaoPendente[maquina.id] = true;
					const marker = await this.localizationService.getPosition(id, maquina);
					this.temRequisicaoPendente[maquina.id] = false;
					const idx = this.markers.findIndex(m => m.id === id);

					if (idx > -1) {
						this.markers[idx] = marker;
					} else {
						this.markers.push(marker);

						// Ao adicionar um novo marcador no mapa, atualiza o zoom
						setImmediate(() => this.autoZoom());
					}

					if (!marker.maquina.summary || !marker.maquina.summary.ultimoEventoEstado) {
						marker.maquina.bgClass = "bg-light";
						return;
					}

					if (marker.maquina.summary.ultimoEventoEstado.estado.tipo_estado === "Parada Programada") {
						marker.maquina.cor = "#faaf40";
						marker.maquina.bgClass = "bg-dark";
					} else if (marker.maquina.summary.ultimoEventoEstado.estado.tipo_estado === "Predisposto") {
						marker.maquina.cor = "#3758b3";
						marker.maquina.bgClass = "bg-light";
					} else if (marker.maquina.summary.ultimoEventoEstado.estado.tipo_estado === "Parada Não Programada") {
						marker.maquina.cor = "#ec1c24";
						marker.maquina.bgClass = "bg-light";
					} else {
						marker.maquina.cor = "#37b34a";
						marker.maquina.bgClass = "bg-light";
					}
				} catch (error) {
					this.temRequisicaoPendente[maquina.id] = false;
					console.error("Não foi possível atualizar o marcador da máquina ID", id);
				}
			},

			async autoZoom () {
				if (!this.markers.length) return;

				const map = await this.$refs.mapRef.$mapPromise;
				const latLngBounds = new google.maps.LatLngBounds();
				for (const marker of this.markers)
					latLngBounds.extend(marker.position);

				map.fitBounds(latLngBounds);
			},

			async updateMachinesSummary () {
				try {
					const summaries = await this.maquinasService.getSummary(this.maquinas.map(m => m.id));
					for (const summary of summaries)
						this.maquinas.find(m => m.id === summary.id).summary = summary;
				} catch (error) {
					console.error(error);
					if (error.response?.status === 403)
						await this.authService.requireLogin();
				}
			},

			getIcon (idMaquina) {
				const exibirCarreta = (this.preferences.find(p => p.nome === "ICONE_MARCADOR") || {}).valor === "CARRETA";
				const summary = this.maquinas.find(m => m.id == idMaquina).summary;
				if (!summary || !summary.ultimoEventoEstado ) {
					if (exibirCarreta) {
						if (summary) {
							summary.width = 100;
							summary.height = 27;
							summary.labelX = 55;
						}

						return "/icons/map-markers/prensagrey.png";
					} else {
						if (summary) {
							summary.width = 27;
							summary.height = 43;
							summary.labelX = 15;
						}

						return "/icons/map-markers/offline.png";
					}
				}

				if (summary.offline && summary.ultimoEventoEstado.estado.tipo_estado != "Parada Programada") {
					if (exibirCarreta) {
						summary.width = 100;
						summary.height = 27;
						summary.labelX = 55;
						return "/icons/map-markers/prensagrey.png";
					} else {
						summary.width = 27;
						summary.height = 43;
						summary.labelX = 15;
						return "/icons/map-markers/offline.png";
					}
				}

				if (exibirCarreta) {
					summary.width = 100;
					summary.height = 27;
					summary.labelX = 55;

					switch (summary.ultimoEventoEstado.estado.tipo_estado) {
						case "Parada Programada":
							return "/icons/map-markers/prensaorange.svg";
						case "Predisposto":
							return "/icons/map-markers/prensablue.svg";
						case "Rodando":
							return "/icons/map-markers/prensagreen.svg";
						case "Parada Não Programada":
						default:
							return "/icons/map-markers/prensared.svg";
					}
				} else {
					summary.width = 27;
					summary.height = 43;
					summary.labelX = 15;

					switch (summary.ultimoEventoEstado.estado.tipo_estado) {
						case "Parada Programada":
							return "/icons/map-markers/orange.png";
						case "Predisposto":
							return "/icons/map-markers/blue.png";
						case "Rodando":
							return "/icons/map-markers/green.png";
						case "Parada Não Programada":
						default:
							return "/icons/map-markers/red.png";
					}
				}
			}
		}
	};
</script>

<style scoped>
	#responsive-map {
		margin-top: 66px;
	}
</style>

<style>
	.map, .alarms-list {
		position: absolute;
		height: calc(100% - 66px);
	}

	.alarms-list {
		right: 0;
	}

	@media (max-width: 850px) {
		.vue-map, .map, .vue-map-container {
			position: relative !important;
			width: 100% !important;
			height: calc(100vh - 153px) !important;
			margin-top: 0px !important;
		}
	}
</style>
